import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const AffiliateDisclosurePage = () => {
  return (
    <Layout>
      <SEO
        title="Friskrivning och Partnerskap"
        description={`Vi analyserar produkter och tjänster efter vår bästa förmåga men det är ingen garanti för att informationen vi besitter är den senaste och är korrekt. Vi tar inget ansvar för ditt köp av en produkt eller tjänst som är rekommenderad av oss.`}
      />
      <div className="article-container">
        <h1 className="section-title">Friskrivning</h1>
        <p>
          Vi analyserar produkter och tjänster efter vår bästa förmåga men det
          är ingen garanti för att informationen vi besitter är den senaste och
          är korrekt. Vi tar inget ansvar för ditt köp av en produkt eller
          tjänst som är rekommenderad av oss. Alla artiklar som skrivs är våra
          personliga åsikter och analyser och vi tar inget ansvar för de råd vi
          ger. Du rekommenderas alltid att göra din egen analys baserat på den
          information vi tillhandahåller.
        </p>
        <h1 className="section-title">Partnerskap</h1>
        <p>
          Vi är transparenta med vilka partnerskap vi har så att du själv kan
          bilda dig en uppfattning om vilka artiklar som är påverkade av
          eventuella samarbeten. Vi har för nuvarande inget samarbete.
        </p>
      </div>
    </Layout>
  )
}

export default AffiliateDisclosurePage
